import { FC, useEffect, useState, useCallback } from 'react';
import { graphql, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import sanitizeHtml from 'sanitize-html';
import { format, parseISO } from 'date-fns';

import {
    membershipDependentToAddSelector,
    membershipNewMemberToAddSelector
} from 'state/membership/membership.selector';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import MembershipSendInvitation, { MemberToAdd } from 'display-components/membership/send-invitation';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import Button from 'ui-kit/button/button';

import './membership-send-invite-page.style.scss';
import { membershipSendInviteRoutine } from 'state/membership/membership.routines';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { convertToTitleCase } from 'util/string';
import { MembershipSettingsErrorModal } from 'display-components/membership/modals';
import { accountProfilEPostPatientNumSelector } from 'state/account/account.selectors';

export type SendInviteMembershipProps = {
    data: GatsbyTypes.SendInviteMembershipQuery;
};

type TreatedData = {
    firstName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    prescriptionNumber?: string;
    insuranceID?: string;
    accountType: number;
    email?: string;
    phone?: string;
    ePostPatientNumber?: string;
};

type ModalProps = {
    name: string;
    onClick?: () => void;
};

type InvitationSentModalSendInviteMembershipProps = ModalProps;

const SuccessModalSendInviteMembership: FC<ModalProps> = (props) => {
    const { name, onClick: foreignOnClick } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        if (foreignOnClick) {
            foreignOnClick();
            return;
        }

        dispatch(closeModal({}));
    }, [dispatch, foreignOnClick]);

    return (
        <BirdiModalContent
            icon={'default'}
            title={t(`pages.profile.membership.sendInvite.modals.success.title`)}
            body={
                <div className="membership-send-invite__success-modal">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(t(`pages.profile.membership.sendInvite.modals.success.body`, { name }))
                        }}
                    ></div>
                    <Button
                        className="membership-send-invite__btn-modal"
                        type="button"
                        label={t('pages.profile.membership.sendInvite.modals.button')}
                        onClick={onClick}
                    />
                </div>
            }
        />
    );
};

const InvitationSentModalSendInviteMembership: FC<InvitationSentModalSendInviteMembershipProps> = (props) => {
    const { name, onClick: foreignOnClick } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        if (foreignOnClick) {
            foreignOnClick();
            return;
        }

        dispatch(closeModal({}));
    }, [dispatch, foreignOnClick]);

    return (
        <BirdiModalContent
            icon={'default'}
            title={t(`pages.profile.membership.sendInvite.modals.invitationSent.title`)}
            css="text-slate"
            body={
                <div className="membership-send-invite__modal">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                t(`pages.profile.membership.sendInvite.modals.invitationSent.body`, { name })
                            )
                        }}
                    ></div>
                    <Button
                        className="membership-send-invite__btn-modal"
                        type="button"
                        label={t('pages.profile.membership.sendInvite.modals.button')}
                        onClick={onClick}
                    />
                </div>
            }
        />
    );
};

export const SendInviteMembership: FC<SendInviteMembershipProps> = ({ data }) => {
    const dependentToAddSelector = useSelector(membershipDependentToAddSelector);
    const newMemberToAddSelector = useSelector(membershipNewMemberToAddSelector);
    const profileEPostNum = useSelector(accountProfilEPostPatientNumSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState(false);
    const [memberData, setMemberData] = useState<Partial<TreatedData>>({});
    const [isMemberMinor, setIsMemberMinor] = useState<boolean>(false);

    useEffect(() => {
        if (dependentToAddSelector) {
            const parsedDateOfBirth = parseISO(dependentToAddSelector.dateOfBirth);
            setMemberData({
                firstName: dependentToAddSelector.firstName,
                lastName: dependentToAddSelector.lastName,
                age: String(dependentToAddSelector.age),
                dateOfBirth: format(parsedDateOfBirth, 'MM/dd/yyyy'),
                prescriptionNumber: dependentToAddSelector.prescriptionNumber,
                accountType: dependentToAddSelector?.age && dependentToAddSelector.age < 18 ? 1 : 2, // Minor / Adult only
                email: dependentToAddSelector.email,
                phone: dependentToAddSelector.phoneNumber,
                ePostPatientNumber: dependentToAddSelector.ePostPatientNumber
            });
        }
        if (newMemberToAddSelector) {
            const parsedDateOfBirth = parseISO(newMemberToAddSelector.dateOfBirth);
            setMemberData({
                firstName: newMemberToAddSelector.firstName,
                lastName: newMemberToAddSelector.lastName,
                age: String(newMemberToAddSelector.age),
                dateOfBirth: format(parsedDateOfBirth, 'MM/dd/yyyy'),
                accountType: newMemberToAddSelector.age < 18 ? 1 : 2, // Minor / Adult only
                email: newMemberToAddSelector.emailAddress,
                phone: newMemberToAddSelector.mobilePhone,
                ePostPatientNumber: newMemberToAddSelector.ePostPatientNumber
            });
        }
    }, [dependentToAddSelector, newMemberToAddSelector]);

    useEffect(() => {
        setIsMemberMinor((memberData.age && Number(memberData.age) <= 12) as boolean);
    }, [memberData]);

    const onClickModalHandler = () => {
        dispatch(closeModal({}));
        navigate('/secure/profile/membership');
    };
    const handleOnCancel = () => {
        navigate('/secure/profile/membership', { state: memberData ?? undefined });
    };

    const closeModalHandler = () => {
        dispatch(closeModal({}));
    };

    const showGenericErrorModal = useCallback(
        (memberName: string, variant: 'sendInvitation' | 'addMember' | 'sendInvitationAssociated') => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => dispatch(closeModal({})),
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation={variant}
                            memberName={convertToTitleCase(memberName)}
                            t={t}
                            onClose={() => dispatch(closeModal({}))}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const handleOnSubmit = (contactMethod?: string) => {
        setIsBusy(true);
        const ModalComponent = isMemberMinor
            ? SuccessModalSendInviteMembership
            : InvitationSentModalSendInviteMembership;

        dispatch(
            membershipSendInviteRoutine.trigger({
                data: {
                    communicationType: contactMethod,
                    ePostPatientNumber: memberData.ePostPatientNumber,
                    holderEPostPatientNumber: profileEPostNum
                },
                onSuccess: () => {
                    setIsBusy(false);

                    dispatch(
                        openModal({
                            showClose: true,
                            onClose: closeModalHandler,
                            bodyContent: (
                                <ModalComponent name={String(memberData.firstName)} onClick={onClickModalHandler} />
                            ),
                            ctas: []
                        })
                    );
                },
                onFailure: () => {
                    setIsBusy(false);
                    showGenericErrorModal(memberData.firstName || '', 'sendInvitation');
                }
            })
        );
    };
    return (
        <div className="membership-send-invite__wrapper">
            <WorkflowLayout backgroundImage={data.feathersBackground} useRoundedCorners={false} anonymousOnly={false}>
                <WorkflowLayoutFormWrapper
                    eyebrowText={t('pages.profile.membership.sendInvite.eyebrowText')}
                    title={t('pages.profile.membership.sendInvite.title')}
                >
                    <MembershipSendInvitation
                        dependentData={memberData as MemberToAdd}
                        isBusy={isBusy}
                        onSubmit={(contactMethod: string) => {
                            handleOnSubmit(contactMethod);
                        }}
                        onCancel={handleOnCancel}
                    />
                </WorkflowLayoutFormWrapper>
            </WorkflowLayout>
        </div>
    );
};

export default SendInviteMembership;

export const query = graphql`
    query SendInviteMembership($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        feathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
