import { useLocation } from '@reach/router';
import { useMatch } from '@reach/router';
import classNames from 'classnames';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import React, { useCallback, useEffect, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';

import BirdiModal from 'components/birdi-modal/birdi-modal';
import CookieBanner from 'components/cookie-banner/cookie-banner.component';
import { leftNavigationPaths } from 'components/navigation/navigation';
import Navigation from 'components/navigation/navigation.component';
import { NavPath } from 'components/navigation/navigation.props';
import SEO from 'components/seo/seo.component';
import { SEOProps } from 'components/seo/seo.props';

import { accountIsLoggedInSelector, accountProfileSelector } from 'state/account/account.selectors';
import { accountIsMembershipSelector } from 'state/account/account.selectors';

import { InitOptimize } from 'util/google_optimize/optimize_helper';
import { filterNavigationPaths } from 'util/navigation';

import useUmichSite from 'hooks/useUmichSite';

import withBackgroundImage from 'hoc/withBackgroundImage';
import withBasicAuth from 'hoc/withBasicAuth';
import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

import './workflow.layout.scss';

const WorkflowLayout = ({
    children,
    className,
    metaData,
    anonymousOnly,
    hideNavLinks = false
}: {
    children: any;
    className?: string;
    metaData?: SEOProps;
    anonymousOnly?: boolean;
    hideNavLinks?: boolean;
}) => {
    const data = AlertBannerAndMenusQuery();
    const location = useMatch('/secure/*');
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const isSecurePage = location !== null;
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const profileObject = useSelector(accountProfileSelector);
    const isUmichSite = useUmichSite();
    const isAccountMembership = useSelector(accountIsMembershipSelector);

    const rightNavPaths = useMemo(
        () => (hideNavLinks ? [] : data.allMenuLinkContentMain.nodes),
        [hideNavLinks, data.allMenuLinkContentMain.nodes]
    );

    const isMembership: boolean | undefined = ENABLE_MEMBERSHIP && isAccountMembership;

    const filterPaths = useCallback(
        (navItem: NavPath) => filterNavigationPaths(navItem, isSecurePage, isLoggedIn, !!isMembership, isUmichSite),
        [isMembership, isLoggedIn, isSecurePage, isUmichSite]
    );

    const rightNavFilteredPaths = useMemo(
        () => rightNavPaths.filter((navItem: NavPath) => filterPaths(navItem)),
        [rightNavPaths, filterPaths]
    );

    const leftNavFilteredPaths = useMemo(() => {
        return leftNavigationPaths.filter((navItem: NavPath) => filterPaths(navItem));
    }, [filterPaths]);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path?.alias?.toString()
    );

    useEffect(() => {
        InitOptimize();
    }, []);

    const classes = classNames('page', className);

    if (anonymousOnly && isLoggedIn && profileObject) {
        navigate('/secure/medicine-cabinet');
        return null;
    }

    return (
        <div className={`workflowLayout ${classes}`}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                applicationPageMetaData={applicationPageParagraphs[0]?.field_meta_tags}
            >
                {children}
            </SEO>
            <Navigation
                rightMenuPaths={rightNavFilteredPaths}
                isSecurePage={isSecurePage}
                isLoggedIn={isLoggedIn}
                leftMenuPaths={leftNavFilteredPaths}
            />
            <Container fluid={'xl'} className="px-0 px-xl-3">
                <Row className="workflowContentContainer no-gutters">
                    <Col xs={12} md={{ span: 8, offset: 2 }}>
                        {children}
                    </Col>
                </Row>
            </Container>
            <BirdiModal />
            <CookieBanner />
        </div>
    );
};

export default withBasicAuth(
    withSessionExpiration(withSecurity(withBackgroundImage(WorkflowLayout, 'workflowPageBackground')))
);

export const AlertBannerAndMenusQuery = () => {
    const AlertBannerAndMenus = useStaticQuery<any>(graphql`
        {
            allBlockContentAlertBanner(
                sort: { fields: changed, order: DESC }
                filter: { field_alert_active: { eq: true } }
            ) {
                nodes {
                    field_alert_text
                    field_alert_priority_level
                    field_alert_permanent
                    field_alert_link {
                        title
                        uri
                    }
                }
            }
            allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    isButton: field_menu_cta
                    link {
                        url
                    }
                    label: title
                    requiresAccountAuth: field_requires_account_auth
                    requiresMembership: field_requires_membership
                    isMobile: field_menu_only_mobile
                    buttonVariant: field_menu_button_variant
                    langcode
                }
            }
            allNodeApplicationPage {
                nodes {
                    path {
                        langcode
                        alias
                    }
                    field_meta_tags {
                        title
                        description
                        abstract
                        keywords
                    }
                }
            }
        }
    `);
    return AlertBannerAndMenus;
};
